<template>
  <q-footer
    :class="$q.screen.lt.sm ? '' : 'hidden'"
    class="bg-footer-menu flex justify-between items-end"
  >
    <div v-show="!$q.loading.isActive">
      <WalletButton
        class="absolute-top q-pt-sm"
        v-if="mainStore.isLogin && !mainStore.loadingProfile"
      />
      <AuthButton
        class="absolute-top q-pt-sm"
        v-if="!mainStore.isLogin && mainStore.loadingProfile"
      />
    </div>
    <div
      class="flex items-center justify-center q-pb-xs q-mr-xs"
      v-for="(item, index) in mapMenuList"
      :key="index"
    >
      <q-img
        :src="item.image"
        no-spinner
        loading="eager"
        class="set-icon"
        :class="{ 'disabled-menu-footer': item.isDisabled }"
        @click="
          item.isDisabled
            ? (isMaintain = true)
            : item.route === '/exchange'
            ? checkBank(item.route)
            : $router.push(item.route)
        "
      >
        <!-- @click="$router.push(item.route)"-->
      </q-img>
    </div>
  </q-footer>
  <q-footer
    :class="$q.screen.lt.sm ? 'hidden' : 'flex justify-center'"
    style="width: 100dvw"
  >
    <div class="bg-footer-menu-l flex justify-between q-px-lg">
      <div v-show="!$q.loading.isActive">
        <WalletButton v-if="mainStore.isLogin && !mainStore.loadingProfile" />
        <AuthButton v-if="!mainStore.isLogin && mainStore.loadingProfile" />
      </div>
      <div></div>
      <div class="flex justify-around">
        <div
          class="flex column items-center"
          :class="{ 'q-mr-md': index < menuList.length - 1 }"
          v-for="(item, index) in mapMenuList"
          :key="index"
        >
          <q-img
            :src="item.image"
            no-spinner
            loading="eager"
            class="set-icon-l cursor-pointer"
            :class="{ 'disabled-menu-footer': item.isDisabled }"
            @click="
              item.isDisabled
                ? (isMaintain = true)
                : item.route === '/exchange'
                ? checkBank(item.route)
                : $router.push(item.route)
            "
          >
          </q-img>
        </div>
      </div>
    </div>
  </q-footer>
  <PageMaintain v-model:is-open-modal="isMaintain" />
</template>

<script setup lang="ts">
import { useMainStore } from "~/store/mainStore";
import { useBank } from "~/store/bankStore";
import { useRouter } from "vue-router";
import { useController } from "~/store/controllerStore";
import { ref, computed } from "vue";
import PageMaintain from "~/components/maintain/PageMaintain.vue";

export interface MenuList {
  image: string;
  route: string;
  isDisabled: boolean;
}

const bank = useBank();
const controller = useController();
const mainStore = useMainStore();
const router = useRouter();
const isMaintain = ref<boolean>(false);
const menuList = ref<MenuList[]>([
  {
    image: "/footer/icon-promotion.png",
    route: "/promotion",
    isDisabled: false,
  },
  {
    image: "/footer/icon-like.png",
    route: "/lobby/favGame",
    isDisabled: false,
  },
  {
    image: "/footer/icon-mission.png",
    route: "/mission",
    isDisabled: false,
  },
  {
    image: "/footer/mini_game.png",
    route: "/random-reward",
    isDisabled: false,
  },
  {
    image: "/footer/icon-shop.png",
    route: "/exchange",
    isDisabled: false,
  },
  {
    image: "/footer/icon-tournament.png",
    route: "/tournament",
    isDisabled: false,
  },
]);

const mapMenuList = computed(() => {
  return menuList.value.map((item) => {
    const dataIndex = controller.buttonControllers.findIndex(
      (button) => button.path === item.route
    );
    if (dataIndex !== -1) {
      item.isDisabled = !controller.buttonControllers[dataIndex].status;
    }
    return {
      ...item,
    };
  });
});

const checkBank = (path: string): void => {
  bank.checkVerifyBank(router, path);
};
</script>

<style scoped>
.disabled-menu-footer {
  filter: grayscale(100%);
}

.bg-footer-menu {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  height: 17dvh !important;
  margin: 0;
  padding: 0;
  max-height: 135px !important;
  background-color: rgba(248, 169, 0, 0.07);
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.bg-footer-menu-l {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  height: 15dvh !important;
  display: flex;
  align-items: center;
  justify-items: center;
  width: 85dvw;
  max-width: 1200px;
  position: absolute;
  bottom: 0;
  max-height: 80px !important;
  background-color: rgba(248, 169, 0, 0.07);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.set-icon {
  width: 12.2vw;
  max-width: 52px;
  margin-bottom: 2px;
}

.set-icon-l {
  width: 5dvw;
  max-width: 50px;
}

@media screen and (min-width: 1024px) {
  .bg-footer-menu {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    height: 20dvh !important;
    background-color: rgba(248, 169, 0, 0.07);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .bg-footer-menu-l {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    height: 20dvh !important;
    width: 91%;
    max-width: 1200px;
    position: absolute;
    bottom: 0;
    max-height: 120px !important;
    background-color: rgba(248, 169, 0, 0.07);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .set-icon {
    width: 10vw;
    max-width: 40px;
  }

  .set-icon-l {
    width: 5vw;
    max-width: 75px;
  }
}
</style>
