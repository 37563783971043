<template>
  <TopBar :profile="mainStore.profile" :avatar="mainStore.avatar" @update:is-show-news="args => {showNews = true}" />
  <div class="row  items-center" :class="$q.screen.lt.sm ? '':'hidden'">
    <div class="col-12 col-md-6 full-height full-width q-pt-xs">
      <div class=" full-height full-width flex">
        <BlockCredit :get-credit="mainStore.getCredit" :loading="mainStore.loadCredit" :balance="mainStore.balance" />
        <q-space></q-space>
        <BlockSetting :agent-prefix="mainStore.agentPrefix" />
      </div>
    </div>
    <div class="col-12 col-md-6">
      <MenuP :class="{'q-mt-md':!$q.screen.gt.sm}" />
    </div>
  </div>
  <div :class="$q.screen.lt.sm?'hidden':''" class="flex items-center justify-center set-block-top-header q-px-md ">
    <div class="row items-center full-width">
      <BlockCredit :get-credit="mainStore.getCredit" :loading="mainStore.loadCredit" :balance="mainStore.balance"
                   :is-horizontal="true" />
      <BlockSetting :is-horizontal="true" :menu-list="menu.menuList" />
    </div>
  </div>
  <NewsAlert v-model:is-show-news="showNews" />
  <PageMaintain v-model:is-open-modal="isMaintain" />
</template>

<script setup lang="ts">
import {useMenu} from "~/store/menuStore";
import {useMainStore} from "~/store/mainStore";
import PageMaintain from "~/components/maintain/PageMaintain.vue";
import TopBar from "~/components/header/child/TopBar.vue";
import BlockCredit from "~/components/header/child/BlockCredit.vue";
import BlockSetting from "~/components/header/child/BlockSetting.vue";

const menu = useMenu()
const mainStore = useMainStore()
const isMaintain = ref<boolean>(false)
const showNews = ref<boolean>(false)

onMounted(async (): Promise<void> => {
  await getNews()
})
const getNews = async (): Promise<void> => {
  await mainStore.getNotify()
  if (localStorage.getItem('first_login') === 'TRUE') {
    showNews.value = true
    localStorage.setItem('first_login', 'FALSE')
  }
}

</script>

<style scoped lang="scss">
.set-block-top-header {
  display: flex;
  width: 85dvw;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .set-block-top-header {
    display: flex;
    width: 100dvw;
    max-width: 1200px;
    align-items: center;
  }
}
</style>
