<template>
  <div class="set-block-top-header  q-px-md">
    <div :style="$q.screen.lt.sm ?'margin-left: -15px':''">
      <q-img :src="props.avatar" no-spinner loading="eager" class="set-logo"></q-img>
    </div>
    <q-space v-if="$q.screen.lt.sm"></q-space>
    <div class="flex items-center">
      <div class="set-font-nickname q-pl-md q-pr-sm text-right">
        <span id="nickname">
        {{
            mainStore.profile?.nickName || 'upbetxTH'
        }}
          </span>
      </div>
      <div>
        <q-img :src="`${baseURLImageAvatar}${mainStore.profile?.avatar||'4'}.png`"
               no-spinner
               loading="eager"
               @click="$router.push('/setting/profile')"
               class="q-mr-md set-icon-top-header cursor-pointer"></q-img>
      </div>
      <div>
        <q-img src="https://opacity.mo.cloudinary.net/small/game-app/news-1.png"
               no-spinner loading="eager"
               @click="emit('update:is-show-news', true)"
               class="set-icon-top-header cursor-pointer">
        </q-img>
      </div>
    </div>
  </div>
</template>

<script setup>
import Hotjar from '@hotjar/browser'

const props = defineProps({
  avatar: {
    type: String,
    default: '',
  },
  showNews: {
    type: Boolean,
    default: false,
  },
})

import { useMainStore } from '~/store/mainStore'

const emit = defineEmits(['update:is-show-news'])
const mainStore = useMainStore()

const siteId = 3895710
const hotjarVersion = 6

onMounted(() => {
  Hotjar.init(siteId, hotjarVersion)
})

</script>

<style lang="scss" scoped>

.set-block-top-header {
  display: flex;
  width: 85dvw;
  max-width: 1200px;
  margin: 5px auto 0 auto !important;
  align-items: center;
}

.set-font-nickname {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  width: 100px;
  color: #ffffff;
  font-weight: bold;
}

.set-logo {
  width: 40dvw;
  max-width: 158px;
}

.set-icon-top-header {
  width: 9vw;
  max-width: 35px;
}

@media screen and (max-width: 600px) {
  .set-block-top-header {
    display: flex;
    width: 100dvw;
    margin: 10px auto !important;
    max-width: 1200px;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .set-logo {
    width: 40dvw;
    max-width: 200px;
  }
  .set-font-nickname {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 190px;
    font-size: 26px;
    padding-right: 50px;
    color: #ffffff;
    font-weight: bold;
  }

  .set-icon-top-header {
    width: 9vw;
    max-width: 65px;
  }


}

</style>
