<template>
  <div class="text-center ">
    <q-img
        src="/btn/wallet.png"
        no-spinner
        class="animate__animated animate__pulse animate__infinite cursor-pointer set-size-button-wallet"
        @click="checkVerifyBank"
        :class="{'disabled' : controller.buttonControllers.some(item => (item.name === 'wallet') && (item.status === false))
        }"

    ></q-img>
  </div>
</template>
<script setup lang="ts">
import {useMainStore} from "~/store/mainStore";
import {useRouter} from "vue-router";
import {useGame} from "~/store/gameStore";
import {useController} from "~/store/controllerStore";
import {onMounted} from "vue";
import {useSocket} from "~/store/socketStore";

const mainStore = useMainStore()
const router = useRouter()
const controller = useController()
const $q = useQuasar()
const socket = useSocket()
const checkVerifyBank = () => {
  // console.log('socket?.dataAlert', socket?.dataAlert)
  // const dismiss = document.getElementById('q-notify').getElementsByClassName('q-notifications')[0]
  // const myNotification = dismiss.getElementsByClassName('q-notifications__list q-notifications__list--top  fixed column no-wrap items-end')[0]
  // console.log('myNotification before', myNotification)
  //
  // //remove all notification in div myNotification
  // while (myNotification) {
  //   myNotification.remove()
  // }
  // console.log('myNotification after', myNotification)

  if (mainStore.profile?.bankVerifyStatus === 'TRUE') {
    router.push('/wallet')
  } else {
    router.push('/verify-bank')
  }
}

onMounted(() => {
  useGame().clearPathBeforeLogin()
})


const sendPhone = async () => {
  // setInterval(() => {
  //   const randomPhone = Math.floor(Math.random() * 100000000);
  //   console.log(randomPhone)
  //   const phone = '09' + randomPhone
  //   console.log(phone)
  //
  //
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //
  //   let raw = JSON.stringify({
  //     "action": "SEND_OTP",
  //     "body": {
  //       "phone": phone.toString()
  //     }
  //   });
  //
  //
  //   fetch("https://xn--82csfweb7bwa6le1f8k0b.com/api/resetpass.php", {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   })
  //       .then(response => response.text())
  //       .then(result => console.log(result))
  //       .catch(error => console.log('error', error));
  //
  // }, 2000)


  // setInterval(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //
  //   var raw = JSON.stringify({
  //     "action": "GET_DATABYPROD",
  //     "body": {
  //       "id": "1"
  //     }
  //   });
  //
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };
  //
  //   fetch("https://xn--82csfweb7bwa6le1f8k0b.com/api/cate.php", requestOptions)
  //       .then(response => response.text())
  //       .then(result => console.log(result))
  //       .catch(error => console.log('error', error));
  // }, 10)
}

</script>

<style scoped>

.set-size-button-wallet {
  width: 48dvw;
  max-width: 180px;
}


@media screen and (min-width: 600px) {
  .set-size-button-wallet {
    width: 18vw;
    max-width: 150px;
  }

}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
