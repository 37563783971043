<template>
  <q-dialog :model-value='props.isOpenModal'
            @update:model-value="(val) => $emit('update:isOpenModal', val)"
  >
    <q-card class="full-width shadow-10" style="border-radius: 12px">
      <q-card-section class="q-dialog__title text-center q-py-sm text-red">
        ปิดปรับปรุงระบบ !
      </q-card-section>
      <q-separator/>
      <q-card-section>
        <p class="text-red text-weight-medium text-center">
          ปรับปรุงชั่วคราว ระบบจะเปิดให้บริการเร็วๆนี้ ขออภัยในความไม่สะดวกค่ะ
        </p>
      </q-card-section>
      <q-separator/>
      <q-card-section class="text-center q-py-sm">
        <q-btn color="orange-8" push @click="closeModal">ปิด</q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:isOpenModal'])


const closeModal = () => {
  emit('update:isOpenModal', false)
}

</script>

<style lang="scss" scoped>

</style>