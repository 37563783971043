<template>
  <div>
    <q-dialog
        :model-value="isShowNews"
        :position="$q.screen.lt.sm?'top':'standard'"
        transition-show="fade"
        transition-hide="fade"
        transition-duration="100"

        @update:model-value="(val) => $emit('update:isShowNews', val)"

    >
      <div :class="{'q-px-lg shadow-0 absolute':$q.screen.lt.sm}" style="top: 180px">
        <q-card class="full-width shadow-10 rounded-md">
          <q-btn
              icon="close"
              flat
              round
              class="bg-white shadow-10"
              style="
            position: absolute !important;
            z-index: 99;
            right: -5px;
            top: -10px;
          "
              to="/"
              @click="emit('update:isShowNews', false)"
          >
          </q-btn>
          <q-card-section class="text-h6 text-bold text-center q-py-xs">
            ข่าวสารและกิจกรรม
          </q-card-section>
          <div v-if="!$q.screen.lt.sm">
            <template v-if="!mainStore?.notifyData?.news?.data.length">
              <div style="height: 180px;" class="flex justify-center items-center q-pb-lg text-grey-6">
                ไม่มีข้อมูลข่าวสาร !
              </div>
            </template>
            <q-carousel
                v-else
                animated
                ref="carousel"
                control-color="white"
                v-model="slide"
            >
              <q-carousel-slide
                  v-for="item in mainStore.notifyData?.news?.data"
                  :key="item.uuid"
                  :name="item.uuid || mainStore.notifyData?.news?.data[0].uuid"
                  class="rounded-lg"
              >
                <q-img
                    :src="item.image"
                    class="rounded-xs shadow-2"
                    no-spinner
                    no-transition
                    style="max-height: 280px; height: 200px;width: 350px"
                >
                  <div
                      class="flex justify-center full-width absolute absolute-bottom bg-transparent q-mb-xs"
                  >
                    <q-icon
                        name="circle"
                        class="q-mx-xs"
                        size="18px"
                        :class="
                        item.uuid === slide ? 'text-white' : 'text-grey-5'
                      "
                        v-for="item in mainStore.notifyData?.news?.data"
                    ></q-icon>
                  </div>
                </q-img>
              </q-carousel-slide>
              <template
                  v-slot:control
                  v-if="mainStore.notifyData?.news?.data.length"
              >
                <q-carousel-control
                    class="flex flex-center justify-between full-width q-px-lg full-height items-center q-pt-lg"
                >
                  <q-img
                      src="https://opacity.mo.cloudinary.net/small/game-app/left-arrow-news.png"
                      @click="$refs.carousel.previous()"
                      :class="
                      mainStore.notifyData?.news?.data.length <= 1
                        ? 'disabled'
                        : ''
                    "
                      width="35px"
                  ></q-img>
                  <q-img
                      src="https://opacity.mo.cloudinary.net/small/game-app/right-arrow-news.png"
                      width="35px"
                      :class="
                      mainStore.notifyData?.news?.data.length <= 1
                        ? 'disabled'
                        : ''
                    "
                      @click="$refs.carousel.next()"
                  ></q-img>
                </q-carousel-control>
              </template>
            </q-carousel>
            <!--            <div class="col-12 col-sm-5">-->
            <!--              <div class="q-pr-md q-pt-md full-height">-->
            <!--                <p class="q-mb-xs">รายละเอียด</p>-->
            <!--                <div class="overflow-auto" style="height: 78%">-->
            <!--                  <small>-->
            <!--                    {{ findItem(slide)?.title }}-->
            <!--                  </small>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div v-if="$q.screen.lt.sm">
            <template v-if="!mainStore?.notifyData?.news?.data.length">
              <div style="height: 180px;" class="flex justify-center items-center q-pb-lg text-grey-6">
                ไม่มีข้อมูลข่าวสาร !
              </div>
            </template>
            <q-carousel
                v-else
                v-model="slide"
                control-color="white"
                animated
                ref="carousel"
                class="overflow-hidden"
            >

              <template v-for="(item, index) in mainStore.notifyData?.news?.data" :key="index">
                <q-carousel-slide
                    v-for="(item, index) in mainStore.notifyData?.news?.data"
                    :key="item.uuid"
                    :name="item.uuid || mainStore.notifyData?.news?.data[0].uuid"
                    class="overflow-hidden"

                >
                  <q-img :src="item.image" class="rounded-xs" no-spinner no-transition
                         style="max-height: 280px; height: 180px;width: 100%">
                    <div class="flex justify-between full-width bg-transparent items-center full-height">
                      <q-img
                          src="https://opacity.mo.cloudinary.net/small/game-app/left-arrow-news.png"
                          @click="$refs.carousel.previous()"
                          class="q-ml-sm"
                          :class="
                                      mainStore.notifyData?.news?.data.length <= 1
                                        ? 'disabled'
                                        : ''
                                    "
                          width="35px"
                      ></q-img>
                      <q-img
                          src="https://opacity.mo.cloudinary.net/small/game-app/right-arrow-news.png"
                          width="35px"
                          class="q-mr-sm"
                          :class="
                                      mainStore.notifyData?.news?.data.length <= 1
                                        ? 'disabled'
                                        : ''
                                    "
                          @click="$refs.carousel.next()"
                      ></q-img>
                    </div>
                    <div class="flex justify-center full-width absolute absolute-bottom bg-transparent q-mb-xs">
                      <q-icon
                          name="circle"
                          class="q-mx-xs"
                          size="18px"
                          :class="item.uuid === slide ? 'text-white' : 'text-grey-5'"
                          v-for="item in mainStore.notifyData?.news?.data"
                      ></q-icon>
                    </div>
                  </q-img>
                </q-carousel-slide>
              </template>
            </q-carousel>
          </div>
        </q-card>
      </div>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import {useMainStore} from "~/store/mainStore";
import {onMounted} from "vue";

const props = defineProps({
  isShowNews: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:isShowNews"]);
const slide = ref("");
const mainStore = useMainStore();

onMounted(() => {
  initSlide();
});

const initSlide = () => {
  if (mainStore.notifyData?.news?.data?.length) {
    slide.value = mainStore.notifyData?.news?.data[0].uuid;
  }
};

watch(
    () => props.isShowNews,
    (val) => {
      if (val) {
        initSlide();
      }
    },
);
</script>

<style scope>
.q-dialog__inner > div {
  overflow: visible !important;
}

.q-carousel {
  background: none;
  height: 100%
}

.q-carousel__slide, .q-carousel .q-carousel--padding {
  padding: 0 13px 13px 13px !important;
}

@media (min-width: 600px) {
  .q-carousel__slide, .q-carousel .q-carousel--padding {
    padding: 13px !important;
  }
}


@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 400px;
  }
}
</style>
