<template>
  <div class="flex q-mt-sm" :class="$q.screen.lt.sm?'justify-between':''">
    <div v-for="(item,index) in menu.menuList" :key="index">
      <q-img :src="item.img_p"
             class="menu-game cursor-pointer"
             no-spinner
             :style="item.path===$route.path?'':'opacity:0.8'"
             loading="eager"
             :class="$q.screen.lt.sm?'':'q-mr-md'"
             @click="$router.push(item.path)"></q-img>
    </div>
  </div>
</template>
<script setup>
import {useMenu} from "~/store/menuStore";

const menu = useMenu()
</script>

<style>
.menu-game {
  width: 9.9vw;
  max-width: 52px;
}
</style>