import {defineStore} from "pinia";
import {MenuLobbyData} from "~/types/MenuLobbyData";

export interface MenuState {
    menuList: MenuLobbyData[]
}

export const useMenu = defineStore("menuStore", {
    state: (): MenuState => {
        return {
            menuList: [
                {
                    name: "lobby",
                    img: "/menu-l/7.png",
                    img_p: "/menu-p/7.png",
                    path: "/",
                    textTH: 'ล็อบบี้'
                },
                {
                    name: "slot",
                    img: "/menu-l/1.png",
                    img_p: "/menu-p/1.png",
                    path: "/slot",
                    textTH: 'ค่ายเกม',
                }, {
                    name: "rouletteCasino",
                    img: "/menu-l/5.png",
                    img_p: "/menu-p/5.png",
                    path: "/casino",
                    textTH: 'คาสิโน',
                }, {
                    name: "shootingFish",
                    img: "/menu-l/2.png",
                    img_p: "/menu-p/2.png",
                    path: "/fishing",
                    textTH: 'ยิงปลา',
                }, {
                    name: "arcade",
                    img: "/menu-l/4.png",
                    img_p: "/menu-p/4.png",
                    path: "/arcade",
                    textTH: 'อาร์เคต',
                }, {
                    name: "sport",
                    img: "/menu-l/6.png",
                    img_p: "/menu-p/6.png",
                    path: "/sport",
                    textTH: 'กีฬา',
                }
            ]
        }
    },
    actions: {}
})
