<template>
  <div class="text-center q-gutter-x-md">
    <q-img src="/login/login.png"
           class="animate__animated animate__pulse animate__infinite cursor-pointer"
           no-spinner
           :class="$q.screen.lt.sm?'set-size-button-auth':'set-size-button-auth-l'"
           v-if="controller.buttonControllers.some(item => (item.name === 'login') && (item.status === true))"
           @click="$router.push('/login')">
    </q-img>
    <q-img src="/login/register.png"
           no-spinner
           class="animate__animated animate__pulse animate__infinite cursor-pointer"
           :class="$q.screen.lt.sm?'set-size-button-auth':'set-size-button-auth-l'"
           v-if="controller.buttonControllers.some(item => (item.name === 'register') && (item.status === true)) && mainStore.agentPrefix"
           @click="$router.push('/register')">
    </q-img>
  </div>
</template>
<script setup>
import { useMainStore } from '~/store/mainStore'
import { useController } from '~/store/controllerStore'

const mainStore = useMainStore()
const controller = useController()

</script>
<style scoped>

.set-size-button-auth {
  width: 43dvw;
  max-width: 167px;
}

.set-size-button-auth-l {
  width: 18vw;
  max-width: 150px;
}
</style>
