<template>
  <q-layout view="hHh lpR fFf" :class="$q.screen.lt.sm?'setBg1':'setBg2'">
    <q-page-container class="overflow-hidden container">
      <div class="q-mx-sm">
        <Header />
      </div>

      <div ref="blockRef" class="q-mx-sm set-block-content"
           :style="$q.screen.lt.sm ? 'overflow-x:hidden;height:70dvh' : 'height:65%'">
        <NuxtPage />
      </div>
      <!--      *********************** Default Footer ***********************-->
      <Footer />

      <!--      *********************** Theme Footer ***********************-->
      <!--      <FooterTheme />-->
    </q-page-container>
  </q-layout>
</template>
<script setup lang="ts">
import {ref, onMounted,} from "vue";
import {useGame} from "~/store/gameStore";
import {useMainStore} from "~/store/mainStore";
import {useRoute} from "vue-router";
import FooterTheme from "~/components/footer/footer-theme.vue";

const blockRef = ref<any>(null)
const game = useGame()
const route = useRoute()
const mainStore = useMainStore()
const $q = useQuasar()


onMounted(() => {
  blockRef.value.addEventListener('scroll', (e: any) => {
    const height = e.target.scrollHeight - e.target.clientHeight
    const currentScroll = e.target.scrollTop
    if (currentScroll >= (height - 10) && game.page < game.totalPage && !game.isGet) {
      game.setIsGet(true)
    }
  })
})
</script>
<style>
.container {
  height: 100dvh;
  max-width: 1200px; /* Optional: Set a maximum width for the container */
  touch-action: none;
  box-sizing: border-box; /* Include padding and border within the container's total width */
}

.set-block-content {
  width: 85dvw;
  max-width: 1200px;
  margin: 4dvh auto;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .set-block-content {
    width: 100dvw;
    margin: auto;
    max-width: 1200px;
    align-items: center;
  }
}

@media screen and (min-width: 600px) {
  .container {
    height: 100dvh;
    width: 100dvw;
    padding: 0;
    margin: 0 auto;
    max-width: 1200px; /* Optional: Set a maximum width for the container */
    touch-action: none;
    box-sizing: border-box; /* Include padding and border within the container's total width */
  }
}
</style>
